// a proxy function to render media query, but also adding special know
// class for cypress visual testing, as we test print mode by simply adding this
// class into BODY element
export const mediaPrint = (
  style,
  {cypressPrint = true, report = true} = {}
) => ({
  '@media print': style,
  ...(cypressPrint && {'.lookout-cypress-print &': style}), // HACK: for Cypress visual testing
  ...(report && {'.report &': style}), // Dashboard or Smishing report mode
})

const printStyles = {
  '@media not print': {
    '.print-header, .print-footer': {
      display: 'none',
    },
  },
  ...mediaPrint(
    {
      '*, *:before, *:after': {
        printColorAdjust: 'exact',
      },

      'a[href]:after': {
        content: "''",
      },

      '.page-break-before': {
        breakBefore: 'always',
      },

      '.page-break-after': {
        breakAfter: 'always',
      },

      '.no-print': {
        display: 'none !important',
      },

      '.print-header, .print-footer': {
        display: 'flex',
        justifyContent: 'center',
      },

      '.print-header': {
        paddingBottom: 20,

        '.logo-mark': {
          height: 80,
        },
      },

      '.print-footer': {
        paddingTop: 20,

        '.logo': {
          height: 40,
        },
      },

      img: {
        breakInside: 'avoid',
        position: 'relative',
      },

      ['.notifications, button,' +
      ' a[type=button], .lookout-tooltip, .lookout-info-tip,' +
      ' .lookout-pager-previous-button, .lookout-pager-next-button,' +
      ' .lookout-assistant-chat-button, #wootric-modal']: {
        display: 'none !important',
      },

      table: {
        '.row-action-item': {
          display: 'none !important',
        },
      },
    },
    {report: false}
  ),
}

export default printStyles
