var ReactContentView;

import ReactView from './react-view';

export default ReactContentView = (function() {
  // A react view to be used as a top level view in Chaplin Controllers
  class ReactContentView extends ReactView {
    initialize(options) {
      if (!options.className) {
        throw new Error('ReactContentView option className is required.');
      }
      return super.initialize(...arguments);
    }

  };

  ReactContentView.prototype.container = '#content';

  return ReactContentView;

}).call(this);
