import {combineActions, handleActions} from 'redux-actions'
import createSelector from '../../../lib/create-selector'
import {
  FETCH_CATEGORIES,
  FETCH_SUBCATEGORIES,
} from '../../../store/argos-actions'
import {FETCH_GLOBAL_SUBCATEGORIES} from '../../../store/gds-actions'
import {Source} from '../gds-param-types'
import {calcPrevalence} from '../../apps/apps-utils'
import {getAppCountsBySource} from '../security-cloud/analyzed-apps-reducer'
import {c11n, merge} from '../../../lib/utils'
import {isDeployed} from '../../../services/tenancy-helper'
import {SET_CATEGORY, SET_SOURCE} from './app-analysis-actions'

export const getDefaultSource = () =>
  c11n('dashboard.app_analysis.controls.enabled')
    ? isDeployed()
      ? Source.COMPARISON
      : Source.GLOBAL
    : Source.FLEET

const initialCategoriesState = {
  categories: [],
  subcategoriesByCategory: {},
}

export const initialState = {
  isFetching: false,
  isError: false,
  fleet: initialCategoriesState,
  global: initialCategoriesState,
  ui: {
    bindings: {
      categorySelection: null,
      sourceSelection: Source.GLOBAL,
    },
  },
}

const reducer = handleActions(
  {
    [FETCH_CATEGORIES.REQUEST]: state => ({
      ...state,
      isFetching: true,
      isError: false,
    }),

    [FETCH_CATEGORIES.SUCCESS]: (state, {payload}) =>
      merge({}, state, {
        fleet: {categories: payload.fleet},
        global: {categories: payload.global},
        ui: {
          bindings: {
            categorySelection:
              state.ui.bindings.categorySelection ||
              _.head(payload.fleet)?.category_name,
          },
        },
      }),

    [FETCH_CATEGORIES.ERROR]: state => ({...state, isError: true}),

    [FETCH_CATEGORIES.DONE]: state => ({...state, isFetching: false}),

    [FETCH_SUBCATEGORIES.REQUEST]: (state, {payload}) =>
      merge({}, state, {
        fleet: {
          subcategoriesByCategory: {
            [payload.category]: {
              isFetching: true,
              isError: false,
            },
          },
        },
        global: {
          subcategoriesByCategory: {
            [payload.category]: {
              isFetching: true,
              isError: false,
            },
          },
        },
      }),

    [combineActions(
      FETCH_SUBCATEGORIES.SUCCESS,
      FETCH_GLOBAL_SUBCATEGORIES.SUCCESS
    )]: (state, {payload}) =>
      merge({}, state, {
        [payload.source]: {
          subcategoriesByCategory: {
            [payload.category]: {
              isFetching: false,
              isError: false,
              subcategories: _.sortBy(payload.subcategories, 'search_name'),
            },
          },
        },
      }),

    [combineActions(
      FETCH_SUBCATEGORIES.ERROR,
      FETCH_GLOBAL_SUBCATEGORIES.ERROR
    )]: (state, {payload}) =>
      merge({}, state, {
        fleet: {
          subcategoriesByCategory: {
            [payload.category]: {
              isFetching: false,
              isError: true,
            },
          },
        },
        global: {
          subcategoriesByCategory: {
            [payload.category]: {
              isFetching: false,
              isError: false,
            },
          },
        },
      }),

    [SET_CATEGORY.ACTION]: (state, {payload}) =>
      merge({}, state, {
        ui: {bindings: {categorySelection: payload}},
      }),

    [SET_SOURCE.ACTION]: (state, {payload}) =>
      merge({}, state, {
        ui: {bindings: {sourceSelection: payload}},
      }),
  },
  initialState
)

export const getAppAnalysis = ({appAnalysis}) => appAnalysis

export const getUIBindings = state => state.appAnalysis.ui.bindings

export const getFleetCategories = state => state.appAnalysis.fleet.categories

export const createGetFleetCategoriesWithPrevalence = () =>
  createSelector(
    [getFleetCategories, getAppCountsBySource],
    (fleetCategories, appCountsBySource) => {
      const totalAppCount = appCountsBySource[Source.FLEET]

      return _.map(fleetCategories, category => ({
        ...category,
        prevalence: calcPrevalence(category.app_count, totalAppCount) || 0,
      }))
    }
  )

export const getGlobalCategories = state => state.appAnalysis.global.categories

export const createGetCategoryLabels = () =>
  createSelector(getFleetCategories, categories =>
    _.reduce(
      categories,
      (acc, category) => ({
        ...acc,
        [category.category_name]: category.category_label,
      }),
      {}
    )
  )

export const getFleetSubcategoriesByCategory = state =>
  state.appAnalysis.fleet.subcategoriesByCategory

export const getCategoryProp = (state, props) =>
  props ? props.category : false

export const createGetFleetPrevalenceForCategory = () =>
  createSelector(
    [createGetFleetCategoriesWithPrevalence(), getUIBindings, getCategoryProp],
    (fleetCategories, {categorySelection}, category) => {
      if (!_.isEmpty(fleetCategories)) {
        return _.find(fleetCategories, [
          'category_name',
          category?.category_name || categorySelection,
        ])?.prevalence
      }

      return null
    }
  )

export const createGetGlobalPrevalenceForCategory = () =>
  createSelector(
    [getGlobalCategories, getUIBindings, getCategoryProp],
    (globalCategories, {categorySelection}, category) => {
      if (!_.isEmpty(globalCategories)) {
        return _.find(globalCategories, [
          'categoryName',
          category?.category_name || categorySelection,
        ])?.prevalence
      }

      return null
    }
  )

export const createGetFleetSubcategoriesForCategory = () =>
  createSelector(
    [getFleetSubcategoriesByCategory, getUIBindings, getCategoryProp],
    (subcategoriesByCategory, {categorySelection}, category) =>
      _.get(
        subcategoriesByCategory[category?.category_name || categorySelection],
        'subcategories',
        []
      )
  )

export const getGlobalSubcategoriesByCategory = state =>
  state.appAnalysis.global.subcategoriesByCategory

export const createGetGlobalSubcategoriesForCategory = () =>
  createSelector(
    [getGlobalSubcategoriesByCategory, getUIBindings, getCategoryProp],
    (subcategoriesByCategory, {categorySelection}, category) =>
      _.get(
        subcategoriesByCategory[category?.category_name || categorySelection],
        'subcategories',
        []
      )
  )

export const createGetSubcategoriesForCategory = () =>
  createSelector(
    [
      createGetFleetSubcategoriesForCategory(),
      getAppCountsBySource,
      createGetGlobalSubcategoriesForCategory(),
    ],
    (fleetSubcategories, appCountsBySource, globalSubcategories) =>
      _.reduce(
        fleetSubcategories,
        (acc, {search_name, search_label, app_count}) => [
          ...acc,
          {
            name: search_name,
            label: search_label,
            fleet: {
              prevalence: calcPrevalence(
                app_count,
                appCountsBySource[Source.FLEET]
              ),
            },
            global: {
              prevalence: _.get(
                _.find(globalSubcategories, ['searchName', search_name]),
                'prevalence'
              ),
            },
          },
        ],
        []
      )
  )

export const getCategoryFetchState = ({appAnalysis}) =>
  _.pick(appAnalysis, ['isFetching', 'isError'])

export const createGetSubcategoryFetchState = () =>
  createSelector(
    [getAppAnalysis, getUIBindings, getCategoryProp],
    (appAnalysis, {categorySelection}, category) => {
      const fleetFetchState = _.pick(
        appAnalysis.fleet.subcategoriesByCategory[
          category?.category_name || categorySelection
        ],
        ['isFetching', 'isError']
      )

      const globalFetchState = _.pick(
        appAnalysis.global.subcategoriesByCategory[
          category?.category_name || categorySelection
        ],
        ['isFetching', 'isError']
      )

      return {
        isFetching: fleetFetchState.isFetching || globalFetchState.isFetching,
        isError: fleetFetchState.isError || globalFetchState.isError,
      }
    }
  )

export const createGetFetchState = () =>
  createSelector(
    [getCategoryFetchState, createGetSubcategoryFetchState()],
    (categoryFetchState, subcategoryFetchState) => ({
      isFetching: subcategoryFetchState.isFetching,
      isError: categoryFetchState.isError || subcategoryFetchState.isError,
    })
  )

export default reducer
