import {handleActions} from 'redux-actions'
import moment from 'moment'
import {ipComparator, popularityBucket, toProxyURL} from '../../lib/utils'
import createSelector from '../../lib/create-selector'
import {FETCH_APP_DETAILS} from '../../store/argos-actions'
import {
  getFetchState as getAppPropertiesFetchState,
  getAppPropertiesNotification,
  createGetCapabilitiesProperties,
  createGetComponentsProperties,
  createGetPermissionsProperties,
} from './app-properties-reducer'
import {
  getFetchState as getRiskExposureFetchState,
  getRiskExposureNotification,
} from './risk-exposure-reducer'
import {
  getFetchState as getViolationsFetchState,
  getViolationsNotification,
} from './violations-reducer'
import iso3Map from './iso3.json'
import * as appsUtils from './apps-utils'

const SOCIAL_NETWORKS = [
  'facebook_service',
  'twitter_service',
  'instagram_service',
  'linkedin_service',
  'weibo_service',
]

const CLOUD_SERVICES = [
  'facebook_sdk',
  'twitter_sdk',
  'instagram_ipc',
  'linkedin_sdk',
  'google_drive_sdk',
  'google_cloud_storage_sdk',
  'box_sdk',
  'dropbox_sdk',
  'microsoft_one_drive_sdk',
  'amazon_cloud_drive_sdk',
  'ios_cloudkit_framework',
  'amazon_s3_sdk',
  'evernote_sdk',
  ...SOCIAL_NETWORKS,
]

export const initialState = {
  notification: false,
  isFetching: false,
  isError: false,
  isNotFound: false,
  appsById: {},
}

const reducer = handleActions(
  {
    [FETCH_APP_DETAILS.REQUEST]: state => ({
      ...state,
      notification: null,
      isFetching: true,
      isError: false,
      isNotFound: false,
    }),

    [FETCH_APP_DETAILS.SUCCESS]: (state, {payload}) =>
      _.merge({}, state, {
        appsById: {
          [payload.appId]: _.mapValues(payload, (value, key) =>
            key === 'icon_uri' ? toProxyURL(value) : value
          ),
        },
      }),

    [FETCH_APP_DETAILS.ERROR]: (state, {payload}) => {
      if (_.get(payload, 'response.status') === 404) {
        return _.merge({}, state, {
          isNotFound: true,
          isError: true,
        })
      }

      return _.merge({}, state, {
        notification: {
          message: I18n.t('apps.error'),
          isError: true,
        },
        isError: true,
      })
    },

    [FETCH_APP_DETAILS.DONE]: state => ({
      ...state,
      isFetching: false,
    }),
  },
  initialState
)

export const getAppById = (state, {appId}) =>
  state.apps.appDetails.appsById[appId]

export const createGetAppSummary = () =>
  createSelector(getAppById, app => app?.summary)

export const createGetAppIdentification = () =>
  createSelector(getAppById, app => app?.identification)

export const createGetAppPackageName = () =>
  createSelector(
    createGetAppIdentification(),
    identification => identification?.package_name
  )

export const createGetAppName = () =>
  createSelector([getAppById, createGetAppPackageName()], (app, packageName) =>
    _.get(app, 'name', packageName)
  )

export const createGetAppIconUri = () =>
  createSelector(getAppById, app => app?.icon_uri)

export const createGetAppDeveloper = () =>
  createSelector(createGetAppSummary(), summary => summary?.developer)

export const createGetAppVersion = () =>
  createSelector(createGetAppSummary(), summary => summary?.version)

export const createGetAppFileSize = () =>
  createSelector(createGetAppSummary(), summary => {
    if (_.isNumber(_.get(summary, 'file_size'))) {
      return `${(summary.file_size * 0.000001).toFixed(2)}MB`
    }

    return ''
  })

const formatPrevalence = prevalence => ({
  count: prevalence?.count,
  percentage: appsUtils.formatPrevalence(
    appsUtils.parsePrevalence(prevalence?.percentage)
  ),
})

export const createGetMalware = () =>
  createSelector(getAppById, app => app?.malware)

export const createGetFleetAppVersionPrevalence = () =>
  createSelector(createGetAppSummary(), summary =>
    formatPrevalence(summary?.prevalence?.fleet_app_version)
  )

export const createGetFleetAppFamilyPrevalence = () =>
  createSelector(createGetAppSummary(), summary =>
    formatPrevalence(summary?.prevalence?.fleet_app_family)
  )

export const createGetAppSource = () =>
  createSelector(createGetAppSummary(), summary => summary?.prevalence?.source)

export const createGetAppFirstDetected = () =>
  createSelector(createGetAppSummary(), summary => {
    let date
    let time

    if (summary && summary.prevalence.first_seen) {
      const m = moment(summary.prevalence.first_seen)
      date = m.format('ll')
      time = m.format('LT')
    }

    return {date, time}
  })

export const createGetAppStoreId = () =>
  createSelector(getAppById, app =>
    _.last(app?.identification?.app_store_id?.split(':'))
  )

export const createGetAppAcquisitionStatus = () =>
  createSelector(getAppById, app => app?.acquisition_status)

export const createGetAppAnalysisStatus = () =>
  createSelector(getAppById, app => app?.analysis_status)

export const createGetCertificates = () =>
  createSelector(createGetAppIdentification(), identification => {
    if (_.head(identification?.certificates)) {
      return _.head(identification.certificates)
    }

    return ''
  })

export const createGetCertificatesSubject = () =>
  createSelector(createGetCertificates(), certificates => certificates?.subject)

export const createGetHashes = () =>
  createSelector(createGetAppIdentification(), identification =>
    _.extend({sha1: null, md5: null}, identification?.hashes)
  )

export const createGetObjectId = () =>
  createSelector(
    createGetAppIdentification(),
    identification => identification?.file_hash
  )

export const createGetSignerHash = () =>
  createSelector(
    createGetCertificates(),
    certificates => certificates?.signer_hash
  )

export const createGetTeamId = () =>
  createSelector(
    createGetAppIdentification(),
    identification => identification?.team_id
  )

export const createGetAppGenre = () =>
  createSelector(
    createGetAppIdentification(),
    identification => identification?.genre
  )

export const createGetFormattedGenre = () =>
  createSelector(
    createGetAppGenre(),
    genre => genre && _.compact(genre).join(', ')
  )

export const createGetMetadata = () =>
  createSelector(getAppById, app => app?.metadata)

export const createGetPublisher = () =>
  createSelector(createGetAppSummary(), summary => summary?.publisher)

export const createGetContentRating = () =>
  createSelector(createGetMetadata(), metadata => metadata?.content_rating)

export const createGetFormattedContentRating = () =>
  createSelector(
    createGetContentRating(),
    contentRating => contentRating && _.compact(contentRating).join(', ')
  )

export const createGetPrices = () =>
  createSelector(createGetMetadata(), metadata => metadata?.prices)

export const createGetPrice = () =>
  createSelector(createGetPrices(), prices => {
    const price = _.head(_.filter(prices, ['currency', 'USD']))
    const value = price?.value && parseFloat(price.value)

    if (value === 0) {
      return I18n.t('apps.free')
    }

    if (price && _.isNumber(value) && value.toFixed) {
      return `$${value.toFixed(2)}`
    }

    return ''
  })

export const createGetLastAcquired = () =>
  createSelector(createGetMetadata(), metadata => {
    let date

    if (metadata?.last_acquired) {
      const m = moment(metadata.last_acquired)
      date = m.format('ll')
    }

    return date
  })

export const createGetDescription = () =>
  createSelector(getAppById, app => app?.description)

export const createGetDownloads = () =>
  createSelector(createGetMetadata(), metadata =>
    _.extend({low: null, high: null}, metadata?.downloads)
  )

const androidDownloadRangeMap = {
  1: 5,
  5: 10,
  10: 50,
  50: 100,
  500: 1000,
  1000: 5000,
  5000: 10000,
  10000: 50000,
  50000: 100000,
  100000: 500000,
  500000: 1000000,
  1000000: 5000000,
  5000000: 5000000,
}

export const createGetFormattedDownloads = () =>
  createSelector(createGetDownloads(), ({low}) => {
    const high = androidDownloadRangeMap[low]

    if (low && high) {
      if (low === high) {
        // Handle 5,000,000+ downloads
        return `${I18n.numberToDelimited(Math.round(low))}+`
      }

      const formattedHigh = `–${I18n.numberToDelimited(Math.round(high))}`

      return `${high ? '' : '~'}${I18n.numberToDelimited(Math.round(low))}${
        high ? formattedHigh : ''
      }`
    }

    return ''
  })

export const createGetRating = () =>
  createSelector(createGetMetadata(), metadata => metadata?.rating)

export const createGetReviewsCount = () =>
  createSelector(
    createGetMetadata(),
    metadata =>
      metadata?.reviews && I18n.numberToDelimited(Math.round(metadata.reviews))
  )

export const createGetShouldDisplayStoreMetadata = () =>
  createSelector(
    [
      createGetAppStoreId(),
      createGetFormattedGenre(),
      createGetFormattedContentRating(),
      createGetDescription(),
      createGetFormattedDownloads(),
      createGetLastAcquired(),
      createGetPrice(),
      createGetPublisher(),
      createGetRating(),
      createGetReviewsCount(),
    ],
    (...storeMetadata) => _.negate(_.isEmpty)(_.compact(storeMetadata))
  )

export const createGetAppRiskGrade = () =>
  createSelector(getAppById, app => app?.risk_grade)

export const createGetDataSecurity = () =>
  createSelector(getAppById, app => app?.ats)

export const createGetTransportSecurityAssertions = () =>
  createSelector(createGetDataSecurity(), dataSecurity =>
    _.get(dataSecurity, 'transport_security.assertions', [])
  )

export const createGetRawTransportSecurityAssertions = () =>
  createSelector(
    createGetTransportSecurityAssertions(),
    transportSecurityAssertions =>
      _.map(transportSecurityAssertions, ({assertion}) => assertion)
  )

export const createGetStorageSecurityAssertions = () =>
  createSelector(createGetDataSecurity(), dataSecurity =>
    _.get(dataSecurity, 'storage_security.assertions', [])
  )

export const createGetRawStorageSecurityAssertions = () =>
  createSelector(
    createGetStorageSecurityAssertions(),
    storageSecurityAssertions =>
      _.map(storageSecurityAssertions, ({assertion}) => assertion)
  )

export const createGetComponentsAndCloudServices = () =>
  createSelector(getAppById, app => _.get(app, 'components', []))

const isCloudService = ({name}) => _.includes(CLOUD_SERVICES, name)

export const createGetComponents = () =>
  createSelector(
    [createGetComponentsAndCloudServices(), createGetComponentsProperties()],
    (components, componentsProperties) =>
      _.map(components, component => ({
        ...component,
        popularity: popularityBucket(
          _.get(
            _.find(componentsProperties, c => c.codeFamily === component.name),
            'percent'
          )
        ),
      }))
  )

export const createGetRawComponents = () =>
  createSelector(createGetComponents(), components =>
    _.map(components, ({name}) => name)
  )

export const createGetCloudServices = () =>
  createSelector(createGetComponentsAndCloudServices(), components =>
    _.filter(components, isCloudService)
  )

export const createGetPermissions = () =>
  createSelector(getAppById, app => _.get(app, 'permissions_with_weight', []))

export const createGetRawPermissions = () =>
  createSelector(createGetPermissions(), permissions =>
    _.map(permissions, ({name}) => name)
  )

export const createGetPermissionsWithPopularityAndRisk = () =>
  createSelector(
    [createGetPermissions(), createGetPermissionsProperties()],
    (permissions, permissionsProperties) =>
      _.orderBy(
        _.compact(
          _.map(permissions, permission => {
            const popularityPercent = _.get(
              _.find(
                permissionsProperties,
                p => p.permissionName === permission.name
              ),
              'percent'
            )

            return (
              _.isString(permission.name) && {
                type: permission.name,
                descriptionKey: permission.name.replace(
                  'android.permission.',
                  ''
                ),
                popularityPercent,
                popularity: popularityBucket(popularityPercent),
                risk: permission.risk,
              }
            )
          })
        ),
        [c => (_.isNumber(c.risk) ? c.risk : -1)],
        ['desc']
      )
  )

export const createGetCapabilities = () =>
  createSelector(getAppById, app => _.get(app, 'capabilities', []))

export const createGetUriHandlers = () =>
  createSelector(getAppById, app => app?.uri_handlers)

export const createGetCapabilitiesWithPopularityAndRisk = () =>
  createSelector(
    [createGetCapabilities(), createGetCapabilitiesProperties()],
    (capabilities, capabilitiesProperties) =>
      _.orderBy(
        _.map(capabilities, capability => {
          const popularityPercent = _.get(
            _.find(
              capabilitiesProperties,
              c => c.capabilityName === capability.id
            ),
            'percent'
          )

          return {
            ...capability,
            popularityPercent,
            popularity: popularityBucket(popularityPercent),
            risk: capability.risk,
          }
        }),
        [c => (_.isNumber(c.risk) ? c.risk : -1)],
        ['desc']
      )
  )

export const createGetRawCapabilities = () =>
  createSelector(createGetCapabilities(), capabilities =>
    capabilities.map(({id}) => id)
  )

export const getAppDetailsFetchState = state =>
  _.pick(state.apps.appDetails, ['isFetching', 'isError'])

export const createGetFetchState = () =>
  createSelector(
    [
      getAppDetailsFetchState,
      getAppPropertiesFetchState,
      getRiskExposureFetchState,
      getViolationsFetchState,
    ],
    (
      appDetailsFetchState,
      appPropertiesFetchState,
      riskExposureFetchState,
      violationsFetchState
    ) => ({
      isFetching:
        appDetailsFetchState.isFetching ||
        appPropertiesFetchState.isFetching ||
        riskExposureFetchState.isFetching ||
        violationsFetchState.isFetching,
      isError: appDetailsFetchState.isError,
    })
  )

export const getAppIsNotFound = state => state.apps.appDetails.isNotFound

export const getAppDetailsNotification = state =>
  state.apps.appDetails.notification

export const createGetNotification = () =>
  createSelector(
    [
      getAppDetailsNotification,
      getAppPropertiesNotification,
      getRiskExposureNotification,
      getViolationsNotification,
    ],
    (
      appDetailsNotification,
      appPropertiesNotification,
      riskExposureNotification,
      violationsNotification
    ) => {
      if (appPropertiesNotification) {
        return appPropertiesNotification
      }

      if (riskExposureNotification) {
        return riskExposureNotification
      }

      if (violationsNotification) {
        return violationsNotification
      }

      return appDetailsNotification
    }
  )

export const getNetworkActivityByAppId = (state, {appId}) => {
  const appDetails = state.apps.appDetails.appsById[appId]
  return appDetails?.network?.network_traffic || []
}

export const createGetSortedNetworkActivity = () =>
  createSelector(getNetworkActivityByAppId, networkActivity =>
    _.sortBy(networkActivity, 'hostname').map(({details, ...rest}) => ({
      details: details
        ?.map(({encrypted_state, ports, ...detail}) => ({
          encrypted_state: !_.isEmpty(ports) ? encrypted_state : null,
          ports,
          ...detail,
        }))
        .sort((a, b) => ipComparator(a.ip, b.ip)),
      ...rest,
    }))
  )

const concatArrays = (objValue, srcValue) => {
  if (_.isArray(objValue)) {
    return objValue.concat(srcValue)
  }
}

// TODO: (JS-945) Simplify this selector once Argos starts
// returning IPs by country code.
export const createGetNetworkActivityByCountry = () =>
  createSelector(getNetworkActivityByAppId, networkActivity =>
    _.reduce(
      networkActivity,
      (acc, hostname) =>
        _.mergeWith(
          acc,
          _.reduce(
            _.filter(_.get(hostname, 'details', []), 'country_code'),
            (countryAcc, {country_code, ip}) =>
              _.mergeWith(
                countryAcc,
                {
                  [iso3Map[country_code]]: {
                    fillKey: 'networkActivity',
                    ip_addresses: [ip],
                  },
                },
                concatArrays
              ),
            {}
          ),
          concatArrays
        ),
      {}
    )
  )

export const createGetPrivateIpAddresses = () =>
  createSelector(getNetworkActivityByAppId, networkActivity =>
    _.reduce(
      _.flatMap(networkActivity, hostname => hostname.details),
      (acc, value) => (value.is_private ? _.concat(acc, value.ip) : acc),
      []
    )
  )

export default reducer
