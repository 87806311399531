import {TransitionOverridesContext} from '@lookout/ui'
import {createRoot} from 'react-dom/client'
import {createReactContainer} from '../../lib/utils'
import {isInCypressSnapshotTesting} from '../../lib/spec/cypress-helper'

export const renderModal = (className, signal, modalComponent) => {
  const {root, container} = createReactContainer({className}) || {}
  if (!container) return
  const reactRoot = createRoot(container)
  const unmount = () => {
    reactRoot.unmount()
    if (root.contains(container)) root.removeChild(container)
  }
  if (!signal)
    throw new Error(
      'The AbortController signal is required for rendering top level modal!'
    )
  signal.addEventListener('abort', () => unmount())
  reactRoot.render(
    <TransitionOverridesContext.Provider
      value={{transitionDisabled: isInCypressSnapshotTesting()}}
    >
      {modalComponent(unmount)}
    </TransitionOverridesContext.Provider>
  )
}
